<template>
  <a-modal
    width="600px"
    class="enumerator-profile-modal"
    :dialog-style="{ display: 'none' }"
    :footer="null"
    :visible="true"
    :closable="false">
    <h3 class="flex align-center txt-40 txt-bold txt-font-din-medium">
      {{ $t('components.titles.enumeratorProfile') }}
    </h3>
    <a-form layout="vertical" :form="form" :hide-required-mark="true">
      <a-row :gutter="20">
        <a-col :lg="12" :md="24">
          <a-form-item :label="$t('components.titles.firstName')">
            <a-input v-decorator="[
                'firstName',
                {
                  rules: [
                    {
                      required: true,
                      message: $t('components.description.pleaseEnterTheFirstName')
                    },
                  ],
                },
              ]" :disabled="userIsSupervisor" size="large"/>
          </a-form-item>
        </a-col>
        <a-col :lg="12" :md="24">
          <a-form-item :label="$t('components.labels.familyName')">
            <a-input v-decorator="[
                'familyName',
                {
                  rules: [
                    {
                      required: true,
                      message: $t('components.description.pleaseEnterTheFamilyName')
                    },
                  ],
                },
              ]" :disabled="userIsSupervisor" size="large"/>
          </a-form-item>
        </a-col>
      </a-row>
      <a-row :gutter="20">
        <a-col :lg="12" :md="24">
          <a-form-item :label="$t('components.description.gender')">
            <a-select v-decorator="[
              'gender',
              {
                rules: [
                  {
                    required: true,
                    message: $t('components.description.pleaseSelectAGender')
                  },
                ],
              },
            ]"
            :get-popup-container="(triggerNode) => triggerNode.parentNode">
              <a-icon slot="suffixIcon" type="caret-down"/>
              <a-select-option
                :disabled="userIsSupervisor"
                value="female">
                {{ $tc('values.female', 1) }}
              </a-select-option>
              <a-select-option
                :disabled="userIsSupervisor"
                value="male">
                {{ $tc('values.male', 1) }}
              </a-select-option>
              <a-select-option
                :disabled="userIsSupervisor"
                value="unknown">
                {{ $t('components.labels.preferNotToDisclose') }}
              </a-select-option>
            </a-select>
          </a-form-item>
        </a-col>
        <a-col :lg="12" :md="24">
          <a-form-item :label="$t('components.labels.roleInPreviousSurvey')">
            <a-select v-decorator="[
              'role',
              {
                rules: [
                  {
                    required: false
                  },
                ],
              },
            ]"
            :get-popup-container="(triggerNode) => triggerNode.parentNode">
              <a-icon slot="suffixIcon" type="caret-down"/>
              <a-select-option
                :disabled="userIsSupervisor"
                value="not applicable">
                {{ $t('components.dropdown.notApplicable') }}
              </a-select-option>
              <a-select-option
                :disabled="userIsSupervisor"
                value="field supervisor">
                {{ $t('components.dropdown.fieldSupervisor') }}
              </a-select-option>
              <a-select-option
                :disabled="userIsSupervisor"
                value="team leader">
                {{ $t('components.dropdown.teamLeader') }}
              </a-select-option>
              <a-select-option
                :disabled="userIsSupervisor"
                value="measurer">
                {{ $t('components.dropdown.measurer') }}
              </a-select-option>
              <a-select-option
                :disabled="userIsSupervisor"
                value="assistant measurer">
                {{ $t('components.dropdown.assistantMeasurer') }}
              </a-select-option>
            </a-select>
          </a-form-item>
        </a-col>
      </a-row>
      <a-row :gutter="20">
        <a-col :lg="24" :md="24">
          <a-form-item :label="$t('components.titles.language')">
            <a-input v-decorator="[
                'language',
                {
                  rules: [
                    {
                      required: false
                    },
                  ],
                },
              ]" :disabled="userIsSupervisor" size="large"/>
          </a-form-item>
        </a-col>
      </a-row>
      <a-alert
        v-if="!isSupervisor && hasSupervisor(enumerator.groupId)"
        class="mb-20"
        :description="$t('components.description.theSupervisorRoleHasAlreadyBeenAssigned')"
        type="info"
        show-icon/>
      <a-form-item>
        <span slot="label" class="flex align-center">
          {{ $t('components.labels.isThePersonAssignedSupervisor') }}&nbsp;&nbsp;
          <a-tooltip
            :title="$t('components.toolTips.theSupervisorRoleIsReserved')"
            class="ml-8">
            <a-icon type="info-circle" style="color: #c6c7c6; font-size: 20px"/>
          </a-tooltip>
        </span>
        <a-radio-group v-decorator="[
          'supervisor',
          {
            initialValue: false,
            rules: [
              {
                required: true,
                message: $t('components.description.pleasePickEnumeratorSupervisor')
              },
            ],
          },
        ]" :disabled="(!isSupervisor && hasSupervisor(enumerator.groupId)) || userIsSupervisor">
          <a-radio :value="true">
            {{ $t('values.yes') }}
          </a-radio>
          <a-radio :value="false">
            {{ $t('values.no') }}
          </a-radio>
        </a-radio-group>
      </a-form-item>
    </a-form>
    <!-- eslint-disable-next-line vue/no-v-html -->
    <div v-if="metadata && !userIsSupervisor" class="flex justify-center w100" v-html="qr"></div>
    <div class="flex justify-space-between w100">
      <a-button
        class="mt-24"
        type="primary" ghost
        size="large"
        @click="onExit">
        {{ $t('values.cancel') }}
      </a-button>
      <a-button
        class="mt-24"
        type="primary"
        size="large"
        :disabled="userIsSupervisor"
        @click="save">
        {{ $t('components.description.saveAndExit') }}
      </a-button>
    </div>
  </a-modal>
</template>

<script>
import { mapState } from 'vuex';
import { apiPaths } from '../../../../util/request';
import collectQr from '../../../../util/collect-qr';

export default {
  name: 'ViewEnumeratorsModal',
  props: {
    onExit: {
      type: Function,
      required: true
    },
    onEnumeratorUpdate: {
      type: Function,
      required: true
    },
    enumerator: {
      type: Object || undefined,
      default: undefined
    },
    hasSupervisor: {
      type: Function,
      required: true
    },
    team: {
      type: String,
      required: false,
      default: null
    },
    enumeratorIndex: {
      type: Number,
      required: false,
      default: null
    },
    onUpdated: {
      type: Function,
      required: true
    }
  },
  data() {
    return {
      form: this.$form.createForm(this, {
        name: 'update_enumerator',
        mapPropsToFields: () => ({
          firstName: this.$form.createFormField({
            value: this.enumerator.firstName
          }),
          familyName: this.$form.createFormField({
            value: this.enumerator.familyName
          }),
          role: this.$form.createFormField({
            value: this.enumerator.role
          }),
          gender: this.$form.createFormField({
            value: this.enumerator.gender
          }),
          language: this.$form.createFormField({
            value: this.enumerator.language
          }),
          supervisor: this.$form.createFormField({
            value: this.enumerator.supervisor
          })
        })
      })
    };
  },
  computed: {
    ...mapState({
      projectId: state => state.survey.project.id,
      metadata: state => state.survey.metadata,
      userIsSupervisor: state => state.survey.currentUserRoleSystem === 'srvy-sup'
    }),
    isSupervisor() {
      return this.enumerator.supervisor;
    },
    qr() {
      const collectQrOptions = { errorCorrectionLevel: 'L', cellSize: 3 };
      const url = apiPaths.serverUrlForFieldKey(this.enumerator.token, this.projectId);
      return collectQr(url, collectQrOptions, this.enumerator, this.metadata);
    }
  },
  methods: {
    save() {
      this.form.validateFields(async (err, values) => {
        if (!err) {
          try {
            const updatedEnumerator = await this.onUpdated(this.enumerator, values);
            this.$alert().success(this.$t('components.notifications.profileUpdated'));
            this.onEnumeratorUpdate(this.enumeratorIndex, this.team, updatedEnumerator);
            this.onExit();
          } catch (error) {
            this.$alert().danger(this.$t('components.notifications.errorOccuredSavingProfile'));
          }
        }
      });
    }
  }
};
</script>

<style lang="scss" scoped>

</style>
